<template>
  <div class="wy-list-page">

    <floating-button :route="{ name: 'User' }"/>
    <CCard>
      <CCardBody>

        <CAlert color="danger" v-if="!_.isEmpty(error)" closeButton @update:show="error = ''">
          {{ error }}
        </CAlert>

        <CAlert color="success text-center" v-if="!_.isEmpty(message)">
          {{ message }}
        </CAlert>

        <div v-if="!loaded">
          <inline-loader/>
        </div>

        <div v-if="loaded">
          <div class="wy-search-input-container">
            <input
                class="form-control full-width"
                :placeholder="$t('type_here_to_search')"
                type="text"
                v-model="search"
                @input="runSearch"
                @keydown.esc="clearSearch"
            />
            <button class="wy-clear-button" @click="clearSearch" v-if="search !== ''">
              <span class="wy-icon-clear-input"></span>
            </button>
          </div>

          <div v-if="!_.isEmpty(users)">
            <table class="table table-hover wy-list-table">
              <thead>
              <tr>
                <th style="width: 50px;">#</th>
                <th>{{ $t('user') }}</th>
                <th>{{ $t('role') }}</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(user, userIndex) in users"
                  :key="`user_${userIndex}`"
                  :class="{ 'wy-blocked-table-row': user.blocked }">
                <td>{{ user.id }}</td>
                <td>
                  <div class="text-bold">{{ user.name }}</div>
                  <div class="text-muted">{{ user.email }}</div>
                  <div class="text-muted">{{ user.mobile }}</div>
                </td>
                <td>
                  {{ $t(user.role) }}
                </td>
                <td style="width: 120px; white-space: nowrap;">
                  <div class="row">
                    <div class="col">
                      <CButton color="wy-action-button p-0" @click="toggleBlock(userIndex)" :class="{ 'text-red': user.blocked }"><i class="wy-icon-ban p-0"></i></CButton>
                    </div>
                    <div class="col">
                      <CButton color="wy-action-button p-0" @click="editUser(userIndex)"><i class="wy-icon-pencil p-0"></i></CButton>
                    </div>
                    <div class="col">
                      <CButton color="wy-action-button p-0" @click="deleteUser(userIndex)"><i class="wy-icon-delete p-0"></i></CButton>
                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>

            <div class="row wy-pagination-info">
              <div class="col">
                {{ $t('from') }} {{ from }}
                {{ $t('to') }} {{ to }}
              </div>
              <div class="col text-right">
                <strong>{{ $t('total') }}: {{ total }}</strong>
              </div>
            </div>

            <CPagination
                align="center"
                :pages="lastPage"
                :active-page.sync="currentPage"
            />
          </div>
        </div>

      </CCardBody>
    </CCard>

  </div>
</template>

<script>
import { users, deleteUser, unBlockUser, blockUser } from "../../../api/user";
import FloatingButton from "../../components/FloatingButton";
import InlineLoader from "../../components/InlineLoader";
import _ from "lodash";

export default {
  name: 'UsersList',
  components: {
    FloatingButton,
    InlineLoader,
  },
  data() {
    return {
      currentPage: 0,
      activePage: 0,
      firstPage: 0,
      lastPage: 0,
      perPage: 0,
      total: 0,
      from: 0,
      to: 0,
      message: '',
      error: '',
      search: '',
      loaded: false,
      //
      users: [],
    };
  },
  watch: {
    currentPage(newValue) {
      this.getUsers();
      this.$router.replace({name: this.$route.name, params: this.$route.params, query: {page: newValue}});
    },
    '$route.query.page': function (page) {
      if (this._.isUndefined(page)) {
        page = 1;
      }
      this.error = '';
      this.currentPage = page;
    }
  },
  mounted() {
    this.currentPage = Number(this.$route.query.page) || 1;
    this.getUsers();
    this.firstPage = 1;
  },
  methods: {
    getUsers() {
      users(this, this.currentPage, this.search, (response) => {
        this.users = response.users.data;
        this.lastPage = Number(response.users.last_page);
        this.perPage = Number(response.users.per_page);
        this.total = Number(response.users.total);
        this.from = Number(response.users.from);
        this.to = Number(response.users.to);
        this.loaded = true;
        this.$forceUpdate();
      });
    },
    clearSearch() {
      if (this.search !== '') {
        this.search = '';
        this.getUsers();
      }
    },
    runSearch: _.debounce(function() {
      this.currentPage = 1;
      this.getUsers();
    }, 500),
    editUser(userIndex) {
      this.$router.push({ name: 'User', params: {user_id: this.users[userIndex]['id'] } });
    },
    deleteUser(userIndex) {
      this.$store.commit('confirmation', {
        message: this.$t('are_you_sure_that_you_want_to_delete_this_user', { userName: this.users[userIndex].name }),
        onOk: () => {
          this.error = '';
          this.message = '';
          deleteUser(this, this.users[userIndex]['id'], (response) => {
            this.$delete(this.users, userIndex);
            this.total -= 1;
            this.message = response.message;
            if (this.total > this.users.length && this.users.length > 0 && this.currentPage !== this.lastPage) {
              this.getUsers();
            } else if (this.users.length === 0 && this.currentPage > 1) {
              this.currentPage -= 1;
            }
            this.$forceUpdate();
          }, (error) => {
            this.error = error.message;
          });
        }
      })
    },
    toggleBlock(userIndex) {
      if (this.users[userIndex]['blocked']) {
        unBlockUser(this, this.users[userIndex]['id'], () => {
          this.users[userIndex]['blocked'] = false;
        }, (error) => {
          this.error = error.message;
        });
      } else {
        blockUser(this, this.users[userIndex]['id'], () => {
          this.users[userIndex]['blocked'] = true;
        }, (error) => {
          this.error = error.message;
        });
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.wy-list-page {
  .wy-search-input-container::v-deep {
    position: relative;
    input {
      background-color: #F1F1F1;
      [dir=ltr] & {
        padding-right: 40px;
      }

      [dir=rtl] & {
        padding-left: 40px;
      }
    }
    button.wy-clear-button {
      position: absolute;
      background: none;
      border: none;
      top: 8px;
      color: #C41117;
      transition: color 350ms ease;
      [dir=ltr] & {
        right: 5px;
      }

      [dir=rtl] & {
        left: 5px;
      }
      span {
        margin: 0!important;
        font-size: 24px;
      }
      &:focus {
        outline: none;
      }
      &:hover {
        color: #222222;
      }
    }
  }
  .wy-list-table {
    thead {
      th {
        border-top-width: 0;
      }
    }
  }
}
</style>
